exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-404-page-tsx": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---src-pages-dev-404-page-tsx" */),
  "component---src-templates-bar-page-index-tsx": () => import("./../../../src/templates/BarPage/index.tsx" /* webpackChunkName: "component---src-templates-bar-page-index-tsx" */),
  "component---src-templates-contacts-page-index-tsx": () => import("./../../../src/templates/ContactsPage/index.tsx" /* webpackChunkName: "component---src-templates-contacts-page-index-tsx" */),
  "component---src-templates-event-page-index-tsx": () => import("./../../../src/templates/EventPage/index.tsx" /* webpackChunkName: "component---src-templates-event-page-index-tsx" */),
  "component---src-templates-events-page-index-tsx": () => import("./../../../src/templates/EventsPage/index.tsx" /* webpackChunkName: "component---src-templates-events-page-index-tsx" */),
  "component---src-templates-fisio-page-index-tsx": () => import("./../../../src/templates/FisioPage/index.tsx" /* webpackChunkName: "component---src-templates-fisio-page-index-tsx" */),
  "component---src-templates-gallery-page-index-tsx": () => import("./../../../src/templates/GalleryPage/index.tsx" /* webpackChunkName: "component---src-templates-gallery-page-index-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/HomePage/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-location-page-index-tsx": () => import("./../../../src/templates/LocationPage/index.tsx" /* webpackChunkName: "component---src-templates-location-page-index-tsx" */),
  "component---src-templates-offers-page-index-tsx": () => import("./../../../src/templates/OffersPage/index.tsx" /* webpackChunkName: "component---src-templates-offers-page-index-tsx" */),
  "component---src-templates-policy-page-index-tsx": () => import("./../../../src/templates/PolicyPage/index.tsx" /* webpackChunkName: "component---src-templates-policy-page-index-tsx" */),
  "component---src-templates-restaurant-page-index-tsx": () => import("./../../../src/templates/RestaurantPage/index.tsx" /* webpackChunkName: "component---src-templates-restaurant-page-index-tsx" */),
  "component---src-templates-rooms-page-index-tsx": () => import("./../../../src/templates/RoomsPage/index.tsx" /* webpackChunkName: "component---src-templates-rooms-page-index-tsx" */),
  "component---src-templates-services-page-index-tsx": () => import("./../../../src/templates/ServicesPage/index.tsx" /* webpackChunkName: "component---src-templates-services-page-index-tsx" */),
  "component---src-templates-surroundings-page-index-tsx": () => import("./../../../src/templates/SurroundingsPage/index.tsx" /* webpackChunkName: "component---src-templates-surroundings-page-index-tsx" */),
  "component---src-templates-wellness-page-index-tsx": () => import("./../../../src/templates/WellnessPage/index.tsx" /* webpackChunkName: "component---src-templates-wellness-page-index-tsx" */)
}

